<template>
    <v-overlay v-model="this.sprinklerStore.nozzle_overlay" name="nozzles_overlay"
        class="align-top justify-center" no-gutters style="margin-top:15px;height:80vh">
        <v-container style="background-color:white;max-width:500px">
            <v-row style="height:40px;margin:0;padding:0">
                <v-col style="padding:0;margin:0">
                    <img :src="require('@/assets/svg/nelson_tool_nozzle.svg')" style="height:40px;width:auto;" />
                    <h6 style="display:inline-block;vertical-align:top;margin-top:10px">Nozzles</h6>
                    <v-btn style="float:right;margin-right:10px;margin-top:10px" variant="plain" size="medium"
                        @click="this.sprinklerStore.nozzle_overlay = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row style="height:190px;">
                <v-col>
                    <v-card elevation="0" height="100%" width="100%" style="position:relative;">
                        <button v-for="(pressure, index) in this.sprinklerStore.nozzlePressures"
                            :style="btnRegPress(pressure, index)" :key="pressure" :v-model="this.selectedRegPressure"
                            v-on:click="changeRegPressure(pressure, index)">{{
                                    this.displayPsiOrBars(pressure, 0)
                            }}
                        </button>

                        <svg height="50%" width="50%" style="position:absolute;top:25%;left:25%;">
                            <circle cx="50%" cy="50%" r="45%" fill="lightgray" />
                        </svg>
                        <img :src="require('@/assets/svg/meter_needle.svg')" ref="needle1" class="needle" />
                        <label style="position:absolute;top:55%;left:44%;font-weight: bold;">psi</label>
                    </v-card>
                </v-col>
                <v-col>
                    <img :src="require('@/assets/svg/nelson_icons_flow.svg')"
                        style="width:auto;height:20px;margin-left:25%" />
                    <label style="font-weight:bold;vertical-align:top;font-size:small;margin-left:5px">Flow</label>

                    <v-chip outlined style="height:50%;width:80%;text-align: center;">
                        <span style="font-size:300%;font-weight:900;">{{
                                this.displayGallonsOrLiters(this.sprinklerStore.nozzleFlow)
                        }}</span>
                    </v-chip>
                    <br style="margin:10px" />
                    <span :style="dotColor(this.sprinklerStore.selectedFlowNozzle)"
                        style="padding:5px;margin-left:20%;margin-top:15%;font-size:small;width:auto;font-weight:bold;">{{
                                "Nozzle #" + this.sprinklerStore.selectedFlowNozzle
                        }}</span>
                </v-col>
            </v-row>
            <v-row style="border-top: 3px solid black;border-bottom:1px solid black;">
                <v-col>
                    <h6 style="display:inline-block;margin-top:5px;">UNITS</h6>
                </v-col>
                <v-col>
                    <v-btn-toggle label="UNITS" v-model="this.isMetric" borderless rounded style="height:25px;">
                        <v-btn :value="false" text>gpm</v-btn>
                        <v-btn :value="true" text>lpm</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row style="margin:0;margin-top:15px;padding:0;">
                <v-col>
                    <b>Nozzle #</b>
                </v-col>
            </v-row>
            <v-row style="overflow-y:scroll;min-height:30vh;max-height:calc(80vh - 280px);margin:0;padding:0;">
                <v-col>
                    <v-btn style="width:24%;margin-left:calc(1%);height:55px"
                        v-for="nozzle in this.sprinklerStore.allNozzles" :key="nozzle" :style="dotColor(nozzle.number)"
                        v-on:click="this.sprinklerStore.setNozzleFlows(nozzle)">{{
                            nozzle.number
                        }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-overlay>
</template>
<script>
import { useSprinklerStore } from "@/stores/SprinklerStore.js";
import * as UnitConversion from "@/services/UnitConversionService.js";
import gsap from 'gsap';

export default {
    data: () => ({
        baseImgUrl: "https://sprinklerchart.com/img/",
        selectedRegPressure: 0
    }),
    setup() {
        const sprinklerStore = useSprinklerStore();
        return {
            sprinklerStore,
        };
    },
    computed: {
        isMetric: {
            get() {
                return this.sprinklerStore.showMetric;
            },
            set(value) {
                this.sprinklerStore.showMetric = value;
            }
        },
    },
    methods: {
        changeRegPressure(press, index) {
            this.selectedRegPressure = index;
            this.sprinklerStore.selectNozzleForPressure(press);
            gsap.to(this.$refs.needle1, 0.15, { rotation: (40 * index) - 150, transformOrigin: "50% 22px" });
        },
        displayBarsOrPsi(psi) {
            return this.sprinklerStore.showMetric ? UnitConversion.ConvertPSItoBAR(psi).toFixed(1) : psi.toFixed(0);
        },
        displayPsiOrBars(bar, englishDecimals = 1, metricDecimals = 1) {
            const scaledBar = bar / 100000;
            return this.sprinklerStore.showMetric ? scaledBar.toFixed(metricDecimals) : UnitConversion.ConvertBARtoPSI(scaledBar).toFixed(englishDecimals);
        },
        displayGPMAOrLPMH(value) {
            return this.sprinklerStore.showMetric ? value : UnitConversion.ConvertLPMHtoGPMA(value).toFixed(0);
        },
        displayGpmOrLpm(lpm) {
            return this.sprinklerStore.showMetric ? lpm.toFixed(1) : UnitConversion.ConvertLPMtoGPM(lpm).toFixed(1);
        },
        displayFeetOrMeters(meters, englishDecimals = 1, metricDecimals = 1) {
            return this.sprinklerStore.showMetric ? meters.toFixed(metricDecimals) : UnitConversion.ConvertMetersToFt(meters).toFixed(englishDecimals);
        },
        displayInchesOrMillimeters(meters) {
            return this.sprinklerStore.showMetric ? UnitConversion.ConvertMetersToMM(meters).toFixed(1) : UnitConversion.ConvertMetersToIn(meters).toFixed(1);
        },
        displayGallonsOrLiters(liters) {
            return this.sprinklerStore.showMetric ? liters.toFixed(2) : UnitConversion.ConvertLPMtoGPM(liters).toFixed(2);
        },
        dotColor(size) {
            var color = this.sprinklerStore.nozzleColor(size);
            return {
                "background-color": color,
            };
        },
        btnRegPress(value, index) {
            var color = this.sprinklerStore.nozzleColor(this.sprinklerStore.selectedFlowNozzle);
            var left = 0;
            var top = 0;
            switch (index) {
                case 0:
                    left = 10;
                    top = 63;
                    break;
                case 1:
                    left = 1;
                    top = 43;
                    break;
                case 2:
                    left = 7;
                    top = 22;
                    break;
                case 3:
                    left = 26;
                    top = 7;
                    break;
                case 4:
                    left = 52;
                    top = 7;
                    break;
                case 5:
                    left = 72;
                    top = 22;
                    break;
                case 6:
                    left = 77;
                    top = 43;
                    break;
                case 7:
                    left = 68;
                    top = 63;
                    break;

            }

            if (index == this.selectedRegPressure) {
                return {
                    "border": "3px solid lightgray",
                    "border-radius": "50%",
                    "background-color": color,
                    "color": "black",
                    "font-weight": "bold",
                    "width": "20%",
                    "height": "20%",
                    "position": "absolute",
                    "left": left.toString() + "%",
                    "top": top.toString() + "%",
                };
            }
            else {
                return {
                    "border": "3px solid " + color,
                    "border-radius": "50%",
                    "width": "20%",
                    "height": "20%",
                    "color": "black",
                    "font-weight": "bold",
                    "position": "absolute",
                    "left": left.toString() + "%",
                    "top": top.toString() + "%",
                };
            }

        },
    }

};
</script>
<style scoped>
.needle {
    transform: rotate(-150deg);
    width: 20px;
    height: 45px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 10px);
}
</style>