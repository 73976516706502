<template>
    <v-overlay v-model="this.sprinklerStore.compare_overlay" name="compare_overlay" class="align-top justify-center"
        style="margin-top:15px" no-gutters>
        <v-container style="background-color:white;">
            <v-row style="height:30px;" no-gutters>
                <v-col>
                    <img :src="require('@/assets/svg/nelson_tool_compare.svg')"
                        style="height:30px;width:auto;padding:0;margin:0" />
                    <h6 style="display:inline-block;vertical-align:top;margin-top:5px">Compare</h6>
                    <v-btn style="float:right;margin-right:5px;margin-top:5px" variant="plain" size="medium"
                        @click="this.sprinklerStore.compare_overlay = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row style="height:30px;" no-gutters>
                <v-col cols="7">
                    <v-select height="30" v-model="selectedCompareType" return-object
                        :items="this.sprinklerStore.compareTypes" :hint="unitLabel" class=""
                        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                        v-if="this.sprinklerStore.selectedCompareType !== null">
                        <template v-slot:selection="{ item }">
                            <div style="margin-top:15px;">
                                {{ item.title }} ({{ item.raw.englishType }})
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <div @click="this.setCompareType(item)" style="">
                                {{ item.title }} ({{ item.raw.englishType }})
                            </div>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row style="margin-top:10px;height:30vh" no-gutters>
                <v-col>
                    <v-container fluid>
                        <v-row no-gutters style="border-bottom:1px solid black;height:5vh;font-size:larger;">
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                <img :src="require('@/assets/svg/nelson_icons_height.svg')"
                                    style="height:20px;width:50px;justify-self: center;align-self: center;" />
                            </v-col>
                            <v-col class="column" style="border-right:1px solid black;font-weight: bolder;" cols="3">
                                Height</v-col>
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                {{ this.sprinklerStore.showMetric ? "meter" : "feet" }}
                            </v-col>
                            <v-col class="column" cols="3">
                                <v-chip class="chip">
                                    <input class="tbl-input" :value="this.compareHeight"
                                        @blur="this.compareHeight = $event.target.value" />
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters style="border-bottom:1px solid black;height:5vh;font-size:larger;">
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                <img :src="require('@/assets/svg/nelson_icons_pressure.svg')"
                                    style="height:20px;width:50px;" />
                            </v-col>
                            <v-col class="column" style="border-right:1px solid black;font-weight: bolder;" cols="3">
                                Pressure</v-col>
                            <v-col class="column" style="border-right:1px solid black" cols="3">{{
                                    this.sprinklerStore.showMetric ? "bar" : "psi"
                            }}</v-col>
                            <v-col class="column" cols="3">
                                <v-chip class="chip">
                                    <input class="tbl-input" :value="this.comparePressure"
                                        @blur="this.comparePressure = $event.target.value" />
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters style="border-bottom:1px solid black;height:5vh;font-size:larger;">
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                <img :src="require('@/assets/svg/nelson_icons_rate.svg')"
                                    style="height:20px;width:50px;align-self: center;" />
                            </v-col>
                            <v-col class="column" style="border-right:1px solid black;font-weight: bolder;" cols="3">
                                Rate</v-col>
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                {{ this.sprinklerStore.showMetric ? "lpmh" : "gpma" }}
                            </v-col>
                            <v-col class="column" cols="3">
                                <v-chip class="chip">
                                    <input class="tbl-input" :value="this.compareRate"
                                        @blur="this.compareRate = $event.target.value" />
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters style="border-bottom:1px solid black;height:5vh;font-size:larger;">
                            <v-col class="column" style="border-right:1px solid black" cols="3"> <img
                                    :src="require('@/assets/svg/nelson_icons_distance.svg')"
                                    style="height:auto;width:50px;" /></v-col>
                            <v-col class="column" style="border-right:1px solid black;font-weight: bolder;" cols="3">
                                Distance</v-col>
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                {{ this.sprinklerStore.showMetric ? "meter" : "feet" }}
                            </v-col>
                            <v-col class="column" cols="3">
                                <v-chip class="chip">
                                    <input class="tbl-input" :value="this.compareDistance"
                                        @blur="this.compareDistance = $event.target.value" />
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters style="border-bottom:3px solid black;height:5vh;font-size:larger;">
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                <img :src="require('@/assets/svg/nelson_icons_spacing.svg')"
                                    style="height:20px;width:50px;" />
                            </v-col>
                            <v-col class="column" style="border-right:1px solid black;font-weight: bolder;" cols="3">
                                Spacing</v-col>
                            <v-col class="column" style="border-right:1px solid black" cols="3">
                                {{ this.sprinklerStore.showMetric ? "meter" : "feet" }}
                            </v-col>
                            <v-col class="column" cols="3">
                                <v-chip class="chip">
                                    <input class="tbl-input" :value="this.compareSpacing"
                                        @blur="this.compareSpacing = $event.target.value" />
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters style="height:16%;font-size:larger;">
                            <v-col class="column" cols="3">
                                <img :src="require('@/assets/svg/nelson_icons_flow.svg')"
                                    style="height:20px;width:50px" />
                            </v-col>
                            <v-col class="column" cols="3">Flow</v-col>
                            <v-col class="column" cols="3">
                                {{ this.sprinklerStore.showMetric ? "lpm" : "gpm" }}
                            </v-col>
                            <v-col class="column" cols="3" style="font-weight:bolder;">
                                {{ (this.displayGpmOrLpm(this.sprinklerStore.requiredFlow)).toFixed(1) }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-row style="height:6vh" no-gutters>
                <v-col>
                    <h4 style="background-color:mediumseagreen;padding:10px;width:fit-content;float:right">
                        {{ "Nozzle # " + this.sprinklerStore.compareNozzle }}
                    </h4>
                </v-col>
            </v-row>
            <v-row style="height:30px;" no-gutters>
                <v-col>
                    <h5 style="display:inline;">UNITS</h5>
                    <v-btn-toggle style="display:inline-block" label="UNITS" v-model="this.sprinklerStore.showMetric"
                        rounded>
                        <v-btn :value="false" text>IMPERIAL</v-btn>
                        <v-btn :value="true" text>METRIC</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row width="18vw" style="max-height:40vh;height:40vh;overflow-y: scroll;" no-gutters>
                <v-col no-gutters>
                    <table width="100%" fixed-header v-for="plateGrouping in this.sprinklerStore.comparePlates"
                        :key="plateGrouping">
                        <thead style="font-weight:bolder;text-align: center; font-size:larger">
                            <tr>
                                <th><img :src=plateImageUrl(plateGrouping.sprinklerName)
                                        style="width:auto;height:40px;align-self: center;" /></th>
                                <th>{{ plateGrouping.sprinklerName }}</th>
                                <th>{{ this.comparePlateUnits }}</th>
                            </tr>
                        </thead>
                        <tbody style="font-weight:bolder;text-align: center; font-size:larger">
                            <tr v-for="plate in plateGrouping.plates" :key="plate">
                                <td width="25%" style="align-content:center">
                                    <img :src=plateImageUrl(plate.platePn)
                                        class="plate-img" :style="shouldInvert(plate.platePn) ? 'transform:rotate(180deg)' : ''">
                                </td>
                                <td width="50%" style="text-align:center">{{ plate.plateName }}</td>
                                <td width="25%">{{ this.comparePlateValue(plate.value).toFixed(1) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </v-col>
            </v-row>
        </v-container>
    </v-overlay>
</template>
<script>
import { useSprinklerStore } from "@/stores/SprinklerStore.js";
import * as UnitConversion from "@/services/UnitConversionService.js";
import SprinklerService from "@/services/SprinklerService.js";

export default {
    data: () => ({
        baseImgUrl: "https://sprinklerchart.com/img/",
        sprinklers: [],
        selectedCompareType: null
    }),
    setup() {
        const sprinklerStore = useSprinklerStore();
        return {
            sprinklerStore,
        };
    },
    computed: {
        unitLabel: {
            get() {
                return this.sprinklerStore.showMetric ? this.selectedCompareType.metricType : this.selectedCompareType.englishType;
            },
        },
        compareHeight: {
            get() {
                var tmp = this.displayFeetOrMeters(this.sprinklerStore.compareInputs.height, 0);
                return tmp.toFixed(1);
            },
            set(value) {
                this.sprinklerStore.compareInputs.height = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertFtToMeters(value);
                this.sprinklerStore.fetchCompare();
            }
        },
        comparePressure: {
            get() {
                var tmp = this.displayPsiOrBars(this.sprinklerStore.compareInputs.pressure, 0);
                return tmp.toFixed(1);
            },
            set(value) {
                const scaledDownValue = value * 100000; //this is pascals to bar
                this.sprinklerStore.compareInputs.pressure = this.sprinklerStore.showMetric ? scaledDownValue : UnitConversion.ConvertPSItoBAR(scaledDownValue);
                this.sprinklerStore.fetchCompare();
                this.sprinklerStore.fetchNozzleForFlow();
            }
        },
        compareRate: {
            get() {
                var tmp = this.displayGPMAOrLPMH(this.sprinklerStore.compareInputs.rate);
                return tmp.toFixed(1);
            },
            set(value) {
                this.sprinklerStore.compareInputs.rate = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertGPMAtoLPMH(value);
                this.sprinklerStore.fetchCompare();
                this.sprinklerStore.fetchNozzleForFlow();
            }
        },
        compareDistance: {
            get() {
                return this.displayFeetOrMeters(this.sprinklerStore.compareInputs.distance, 0);
            },
            set(value) {
                this.sprinklerStore.compareInputs.distance = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertFtToMeters(value);
                this.sprinklerStore.fetchCompare();
                this.sprinklerStore.fetchNozzleForFlow();
            }
        },
        compareSpacing: {
            get() {
                return this.displayFeetOrMeters(this.sprinklerStore.compareInputs.spacing);
            },
            set(value) {
                this.sprinklerStore.compareInputs.spacing = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertFtToMeters(value);
                this.sprinklerStore.fetchCompare();
                this.sprinklerStore.fetchNozzleForFlow();
            }
        },
        comparePlateUnits: {
            get() {
                return this.sprinklerStore.comparePlateUnits;
            }
        }
    },
    async created() {
        await this.sprinklerStore.setup();
        this.selectedCompareType = this.sprinklerStore.compareTypes[0];
        await SprinklerService.fetchSprinklers().then((response) => {
            this.sprinklers = response;
        });
    },
    methods: {
        setCompareType(value) {
            if (value) {
                this.selectedCompareType = value;
                this.sprinklerStore.setCompareType(value);
            }
        },
        plateImageUrl(platePn) {
            return "https://sprinklerchart.com/img/" + platePn + ".png";
        },
        shouldInvert(platePn) {
            this.sprinklers.forEach((sprinkler) => {
                sprinkler.plates.forEach((sprPlate) => {
                    if (sprPlate.nicpn == platePn) {
                        return sprPlate.orientation == 1;
                    }
                })
            });
            return false;
        },
        displayBarsOrPsi(psi) {
            return this.sprinklerStore.showMetric ? UnitConversion.ConvertPSItoBAR(psi) : psi;
        },
        displayPsiOrBars(bar) {
            const scaledBar = bar / 100000;
            return this.sprinklerStore.showMetric ? scaledBar : UnitConversion.ConvertBARtoPSI(scaledBar);
        },
        displayGPMAOrLPMH(value) {
            return this.sprinklerStore.showMetric ? value : UnitConversion.ConvertLPMHtoGPMA(value);
        },
        displayGpmOrLpm(lpm) {
            return this.sprinklerStore.showMetric ? lpm : UnitConversion.ConvertLPMtoGPM(lpm);
        },
        displayFeetOrMeters(meters) {
            return this.sprinklerStore.showMetric ? meters : UnitConversion.ConvertMetersToFt(meters);
        },
        displayInchesOrMillimeters(meters) {
            return this.sprinklerStore.showMetric ? UnitConversion.ConvertMetersToMM(meters) : UnitConversion.ConvertMetersToIn(meters);
        },
        displayGallonsOrLiters(liters) {
            return this.sprinklerStore.showMetric ? liters : UnitConversion.ConvertLPMtoGPM(liters);
        },
        dotColor(size) {
            var color = this.sprinklerStore.nozzleColor(size);
            return {
                "background-color": color,
            };
        },
        comparePlateValue(value) {
            switch (this.sprinklerStore.selectedCompareType) {
                case 0:
                    return this.displayFeetOrMeters(value);
                case 1:
                case 3:
                    return this.displayInchesOrMillimeters(value);
                default:
                    return value;
            }
        }
    }
};
</script>
<style scoped>
/* .v-field__input {
    padding: 0;
} */
.v-select__selection{
    height:30px;
}
.v-text-field--box .v-input__slot, .v-input__control,
.v-text-field--outline .v-input__slot {
  min-height: auto!important;
  max-height:20px;
  display: flex!important;
  align-items: center!important;
}
  .v-icon{
    margin-top:-10px;
  }

 
.tbl-input {
    text-align: center;
    width: 100%;
    font-weight: bolder;
    font-size: larger;
}

.chip {
    width: 80%;
    height: 80%;
    margin: 5%
}

.column {
    text-align: center;
    align-content: center;
    justify-items: start;
}

.plate-img {
    width:auto;
    height:40px;
    align-content:center;
}
</style>