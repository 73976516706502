import { defineStore } from "pinia";
import SprinklerService from "@/services/SprinklerService.js";

export const useSprinklerStore = defineStore("SprinklerStore", {
  state() {
    return {
      sprinklers: [],
      _selectedSprinkler: null,
      _selectedPlate: null,
      nozzle_overlay: false,
      compare_overlay: false,
      showMetric: false,
      showGroups: false,
      showSecondPressureRange: false,
      firstPressureRangeParameters: [],
      secondPressureRangeParameters: [],
      selectedCoverageHeight: 0,
      selectedUniformityHeight: 0,
      coverageDiameters: {
        heights: [],
        pressures: [],
        nozzles: [],
      },
      streamHeights: {
        heights: [],
        pressures: [],
        nozzles: [],
      },
      uniformity: {
        heights: [],
        pressures: [],
        nozzles: [],
      },
      selectedFlowNozzle: null,
      flowNozzles: [],
      flowNozzleIndex: 0,
      compareInputs: {
        height: 1.8288000000000002,
        pressure: 137895,
        rate: 56.1,
        distance: 408.432,
        spacing: 2.62128,
      },
      compareNozzle: 36,
      comparePlates: [],
      selectedCompareType: 0,
    };
  },
  getters: {
    selectedSprinkler() {
      return this._selectedSprinkler;
    },
    imageUrl() {
      if (this.selectedSprinkler == null) return;
      return "https://sprinklerchart.com/img/" + (this.selectedSprinkler.nameOverride ? this.selectedSprinkler.nameOverride : this.selectedSprinkler.marketing_name) + ".png";
    },
    plates() {
      return this.selectedSprinkler ? this.selectedSprinkler.plates : [];
    },
    selectedPlate() {
      return this._selectedPlate;
    },
    plateParameters() {
      var parameters = (this.showSecondPressureRange && (this.secondPressureRangeParameters.length > 0))
        ? this.secondPressureRangeParameters
        : this.firstPressureRangeParameters;
      if (!this.showGroups) return parameters;
      const groups = [];
      for (const eachParameters of parameters) {
        const group = groups.find(each => each.pmin == eachParameters.pmin && each.pmax == eachParameters.pmax)
        if (group == null) {
          groups.push({
            minSize: eachParameters.size,
            maxSize: eachParameters.size,
            pmin: eachParameters.pmin,
            pmax: eachParameters.pmax,
            spacing: eachParameters.spacing,
          });
          continue;
        }
        group.minSize = Math.min(group.minSize, eachParameters.size);
        group.maxSize = Math.max(group.maxSize, eachParameters.size);
        group.spacing = Math.max(group.spacing, eachParameters.spacing);
      }
      for (const group of groups) {
        group.size = group.minSize + " - " + group.maxSize;
      }
      return groups;
    },
    allNozzles() {
      const nozzles = [];
      nozzles.push({ color: '#777c4f', number: 6, partNumber: "12035-006" });
      nozzles.push({ color: '#777c4f', number: 7, partNumber: "12035-007" });
      nozzles.push({ color: '#c4d8e2', number: 8, partNumber: "12035-008" });
      nozzles.push({ color: '#c4d8e2', number: 9, partNumber: "12035-009" });
      nozzles.push({ color: '#d8b596', number: 10, partNumber: "12035-010" });
      nozzles.push({ color: '#d8b596', number: 11, partNumber: "12035-011" });
      nozzles.push({ color: '#eaaf0f', number: 12, partNumber: "12035-012" });
      nozzles.push({ color: '#eaaf0f', number: 13, partNumber: "12035-013" });
      nozzles.push({ color: '#1eb53a', number: 14, partNumber: "12035-014" });
      nozzles.push({ color: '#1eb53a', number: 15, partNumber: "12035-015" });
      nozzles.push({ color: '#e4c4d6', number: 16, partNumber: "12035-016" });
      nozzles.push({ color: '#e4c4d6', number: 17, partNumber: "12035-017" });
      nozzles.push({ color: '#919693', number: 18, partNumber: "12035-018" });
      nozzles.push({ color: '#919693', number: 19, partNumber: "12035-019" });
      nozzles.push({ color: '#00af99', number: 20, partNumber: "12035-020" });
      nozzles.push({ color: '#00af99', number: 21, partNumber: "12035-021" });
      nozzles.push({ color: '#fcd116', number: 22, partNumber: "12035-022" });
      nozzles.push({ color: '#fcd116', number: 23, partNumber: "12035-023" });
      nozzles.push({ color: '#A03033', number: 24, partNumber: "12035-024" });
      nozzles.push({ color: '#A03033', number: 25, partNumber: "12035-025" });
      nozzles.push({ color: '#ffffff', number: 26, partNumber: "12035-026" });
      nozzles.push({ color: '#ffffff', number: 27, partNumber: "12035-027" });
      nozzles.push({ color: '#008ed6', number: 28, partNumber: "12035-028" });
      nozzles.push({ color: '#008ed6', number: 29, partNumber: "12035-029" });
      nozzles.push({ color: '#3d3023', number: 30, partNumber: "12035-030" });
      nozzles.push({ color: '#3d3023', number: 31, partNumber: "12035-031" });
      nozzles.push({ color: '#d14414', number: 32, partNumber: "12035-032" });
      nozzles.push({ color: '#d14414', number: 33, partNumber: "12035-033" });
      nozzles.push({ color: '#006847', number: 34, partNumber: "12035-034" });
      nozzles.push({ color: '#006847', number: 35, partNumber: "12035-035" });
      nozzles.push({ color: '#44235e', number: 36, partNumber: "12035-036" });
      nozzles.push({ color: '#44235e', number: 37, partNumber: "12035-037" });
      nozzles.push({ color: '#3d332b', number: 38, partNumber: "12035-038" });
      nozzles.push({ color: '#3d332b', number: 39, partNumber: "12035-039" });
      nozzles.push({ color: '#007272', number: 40, partNumber: "12035-040" });
      nozzles.push({ color: '#007272', number: 41, partNumber: "12035-041" });
      nozzles.push({ color: '#c6a00c', number: 42, partNumber: "12035-042" });
      nozzles.push({ color: '#c6a00c', number: 43, partNumber: "12035-043" });
      nozzles.push({ color: '#661e2b', number: 44, partNumber: "12035-044" });
      nozzles.push({ color: '#661e2b', number: 45, partNumber: "12035-045" });
      nozzles.push({ color: '#f1edd1', number: 46, partNumber: "12035-046" });
      nozzles.push({ color: '#f1edd1', number: 47, partNumber: "12035-047" });
      nozzles.push({ color: '#00337f', number: 48, partNumber: "12035-048" });
      nozzles.push({ color: '#00337f', number: 49, partNumber: "12035-049" });
      nozzles.push({ color: '#9b4f19', number: 50, partNumber: "12035-050" });
      return nozzles;
    },
    nozzlePressures() {
      return this.flowNozzles.map((nozzle) => { return nozzle.pressure; });
    },
    nozzleFlow() {
      if (this.flowNozzles.length < 1) return 0.0;
      return this.flowNozzles[this.flowNozzleIndex].flow;
    },
    compareTargets() {
      const plates = [];
      for (const sprinkler of this.sprinklers) {
        if (sprinkler.name.indexOf("PC") != -1) continue;
        for (const plate of sprinkler.plates) {
          plates.push({
            SprName: sprinkler.name,
            PltName: plate.name,
            PlatePn: plate.nicpn,
            Val: 0.0,
          });
        }
      }
      return plates;
    },
    compareTypes() {//why push these and not just declare them?
      const types = [];
      types.push({ title: "COVERAGE DIAMETER", englishType: "feet", metricType: "meter" });
      types.push({ title: "AVERAGE APP. RATE", englishType: "in/hr", metricType: "mm/hr" });
      // types.push({ title: "UNIFORMITY", englishType: "%", metricType: "%" });
      types.push({ title: "STREAM HEIGHTS", englishType: "in", metricType: "mm" });
      return types;
    },
    requiredFlow() {
      if (this.compareInputs.rate <= 0 || this.compareInputs.distance <= 0 || this.compareInputs.spacing <= 0
        || this.compareInputs.rate == null || this.compareInputs.distance == null || this.compareInputs.spacing == null) return 0;
      return (2 * Math.PI * this.compareInputs.distance * this.compareInputs.spacing * this.compareInputs.rate) / 10000;
    },
    comparePlateUnits() {
      const type = this.compareTypes[this.selectedCompareType];
      return this.showMetric ? type.metricType : type.englishType;
    }
  },
  actions: {
    setSprinkler(value) {
      this._selectedSprinkler = value;
      this.setPlate(this._selectedSprinkler.plates[0]);
    },
    async setPlate(value) {
      this._selectedPlate = value;
      await this.fetchData();
    },
    async setCompareType(value) {
      this.selectedCompareType = this.compareTypes.findIndex((type) => type.title == value.title);
      await this.fetchCompare();
    },
    async setup() {
      await this.fetchSprinklers();
      this.setSprinkler(this.sprinklers[0]);
      this.setCompareType(this.compareTypes[0]);
    },
    nozzleColor(size) {
      return this.allNozzles.filter((noz)=> noz.number == size).map((n)=>n.color);
    },
    async fetchData() {
      await this.fetchPlateParameters();
      await this.fetchCoverage();
      await this.fetchStreamHeights();
      await this.fetchUniformity();
      await this.setNozzleFlows(this.allNozzles[0]);
      await this.fetchCompare();
      await this.fetchNozzleForFlow();
      this.selectedNozzlePressure = this.flowNozzles[0].pressure;
      this.selectedCoverageHeight = this.defaultHeightFrom(this.coverageDiameters.heights.slice().reverse());
      this.selectedUniformityHeight = this.defaultHeightFrom(this.uniformity.heights.slice().reverse());
    },
    async fetchSprinklers() {
      await SprinklerService.fetchSprinklers().then((response) => {
        const filteredSprinklers = response.filter(
          (sprinkler) =>
            ["Sprinkler", "PartCircle"].includes(sprinkler.type) &&
            sprinkler.access == 1
        );
        for (const sprinkler of filteredSprinklers) {
          const filteredPlates = sprinkler.plates.filter((plateData) => (plateData.type == null || plateData.type == "3NV") && plateData.access == 1);
          for (const plate of filteredPlates) {
            plate.displayName = function () {
              return this.name;
            };
          }
          sprinkler.plates = filteredPlates;
          sprinkler.displayName = function () {
            return this.nameOverride ? this.nameOverride : this.marketing_name;
          };
          if (sprinkler.name == "PC-R3000") {
            sprinkler.type = "Part Circle";
            sprinkler.nameOverride = "PC-Rotator";
          } else if (sprinkler.name == "PC-S3000") {
            sprinkler.type = "Part Circle";
            sprinkler.nameOverride = "PC-Spinner";
          } else if (sprinkler.name == "PC-D3000") {
            sprinkler.type = "Part Circle";
            sprinkler.nameOverride = "PC-Spray";
          }
        }
        this.sprinklers = filteredSprinklers;
      });
    },
    async fetchPlateParameters() {
      await SprinklerService.fetchPlateParameters(this.selectedSprinkler.name, this.selectedPlate.nicpn, "3NV")
        .then((response) => {
          this.firstPressureRangeParameters = [];
          this.secondPressureRangeParameters = [];
          for (const each of response) {
            if (each.subscription != null) continue;
            if (this.firstPressureRangeParameters.filter((e) => e.size == each.size).length <= 0) {
              this.firstPressureRangeParameters.push(each);
            }
            else {
              this.secondPressureRangeParameters.push(each);
            }
          }
        });
    },
    performanceFrom(response) {
      const data = {
        heights: [],
        pressures: [],
        nozzles: [],
      }
      for (const size of response.NozzleSizes) {
        const nozzle = {
          size: size,
          diameters: [],
        };
        for (let i = 0; i < response.hnodes.length; i++)
        {
          nozzle.diameters.push([]);
        }
        data.nozzles.push(nozzle);
      }
      for (const heightIndex in response.hnodes) {
        data.heights.push(response.hnodes[heightIndex].height);
        data.pressures.push([]);
        for (const pressures of response.hnodes[heightIndex].pressures) {
          data.pressures[heightIndex].push(pressures.pressure);
          for (const nozzleIndex in pressures.distances) {
            if (nozzleIndex >= data.nozzles.length) continue;
            data.nozzles[nozzleIndex].diameters[heightIndex].push({ distance: pressures.distances[nozzleIndex] });
          }
        }
      }
      return data;
    },
    defaultHeightFrom(heights) {
      var defaultHeight = 0;
      if (heights.length != 1) {
        heights.forEach((height, index) => {
          if (height == 1.829) {
            defaultHeight = index;
          }
        })
      }
      return defaultHeight;
    },
    async fetchCoverage() {//these need to be combined into one call that specified the types.
      await SprinklerService.fetchPerformance(0, this.selectedSprinkler.name, this.selectedPlate.nicpn, "3NV")
        .then((response) => {
          if (response.hnodes.length == 0) {
            this.coverageDiameters = {
              heights: [],
              pressures: [],
              nozzles: [],
            }
          } else {
            this.coverageDiameters = this.performanceFrom(response);
          }
        });
    },
    async fetchStreamHeights() {
      await SprinklerService.fetchPerformance(2, this.selectedSprinkler.name, this.selectedPlate.nicpn, "3NV")
        .then((response) => {
          if (response.hnodes.length == 0) {
            this.streamHeights = {
              heights: [],
              pressures: [],
              nozzles: [],
            }
          } else {
            this.streamHeights = this.performanceFrom(response);
          }
        });
    },
    async fetchUniformity() {
      await SprinklerService.fetchPerformance(1, this.selectedSprinkler.name, this.selectedPlate.nicpn, "3NV")
        .then((response) => {
          if (response.hnodes.length == 0) {
            this.uniformity = {
              heights: [],
              pressures: [],
              nozzles: [],
            }
          } else {
            this.uniformity = this.performanceFrom(response);
          }
        });
    },
    async fetchNozzleFlows(nozPn) {
      await SprinklerService.fetchNozzleFlows(nozPn)
        .then((response) => {
          this.flowNozzles = response;
        });
    },
    async fetchNozzleForFlow() {
      await SprinklerService.fetchNozzleForFlow(this.requiredFlow, this.compareInputs.pressure)
        .then((response) => {
          this.compareNozzle = response.size;
        });
    },
    calculateAverageAppRate(rate, distance, diameter) {
      return (0.0377 * distance * rate) / diameter / 1000;
    },
    async fetchCompare() {
      const type = this.selectedCompareType == 0 ? this.selectedCompareType : this.selectedCompareType - 1;
      await SprinklerService.fetchCompare(type, this.compareInputs.height, this.compareInputs.pressure, this.compareNozzle, this.compareTargets)
        .then((response) => {
          this.comparePlates = [];
          for (const plate of response) {
            const groupIndex = this.comparePlates.findIndex((plateGrouping) => plateGrouping.sprinklerName == plate.SprName)
            if (groupIndex == -1) {
              this.comparePlates.push({
                sprinklerName: plate.SprName,
                plates: [],
              })
            }
            const value = this.selectedCompareType == 1 ? this.calculateAverageAppRate(this.compareInputs.rate, this.compareInputs.distance, plate.Val) : plate.Val;
            this.comparePlates[this.comparePlates.length - 1].plates.push({
              platePn: plate.PlatePn,
              plateName: plate.PltName,
              value: value,
            })
          }
        });
    },
    selectNozzleForPressure(pressure) {
      for (const nozzleIndex in this.flowNozzles) {
        if (this.flowNozzles[nozzleIndex].pressure != pressure) continue;
        this.flowNozzleIndex = nozzleIndex;
        return;
      }
    },
    async setNozzleFlows(nozzle) {
      this.selectedFlowNozzle = nozzle.number;
      await this.fetchNozzleFlows(nozzle.partNumber);
    }
  },
});
