
this.ConvertFtToMeters = function (a) {
  return parseFloat(a * .3048);
}
this.ConvertHaToAcre = function (a) {
  return parseFloat(a * 2.47);
}
this.ConvertM2ToAcre = function (a) {
  return parseFloat(a * 0.000247105);
}
this.ConvertM2ToHectare = function (a) {
  return parseFloat(a * 0.0001);
}
this.ConvertAcreToHa = function (a) {
  return parseFloat(a / 2.47);
}
this.ConvertMetersToFt = function (a) {
  return parseFloat(a / .3048);
}
this.ConvertMetersToMM = function (a) {
  return parseFloat(a * 1000);
}
this.ConvertMetersToCM = function (a) {
  return parseFloat(a * 100);
}
this.ConvertMMToMeters = function (a) {
  return parseFloat(a / 1000);
}
this.ConvertMetersToIn = function (a) {
  return parseFloat(a / 0.0254);
}
this.ConvertInToMeters = function (a) {
  return parseFloat(a * 0.0254);
}
this.ConvertPAStoPSI = function (a) {
  return parseFloat(a * 0.000145038);
}
this.ConvertPSItoPAS = function (a) {
  return parseFloat(a / 0.000145038);
}
this.ConvertPAStoBAR = function (a) {
  return parseFloat(a * 0.00001);
}
this.ConvertBARtoPAS = function (a) {
  return parseFloat(a / 0.00001);
}
this.ConvertBARtoPSI = function (a) {
  return parseFloat(a / 0.0689476);
}
this.ConvertPSItoBAR = function (a) {
  return parseFloat(a * 0.0689476);
}
//1 lpm per hectare = 0.106906637 (US gallons per minute) per acre
this.ConvertLPMHtoGPMA = function (a) {
  return parseFloat(a * 0.106906637);
}
this.ConvertGPMAtoLPMH = function (a) {
  return parseFloat(a / 0.106906637);
}
this.ConvertLPMHtoMMPD = function (a) {     // mm/day
  return parseFloat(a * 0.144);
}
this.ConvertGPMAtoINPD = function (a) {     // in/day
  return parseFloat(this.ConvertLPMHtoGPMA(a) * 0.053030);
}
this.ConvertMMPDtoLPMH = function (a) {     // mm/day
  return parseFloat(a / 0.144);
}
this.ConvertINPDtoGPMA = function (a) {     // in/day
  return parseFloat(a / 0.053030);
}
this.ConvertLPMtoGPM = function (a) {
  return parseFloat(a * 0.26417287472922);
}
this.ConvertGPMtoLPM = function (a) {
  return parseFloat(a / 0.26417287472922);
}
this.ConvertLPMtoLPS = function (a) {
  return parseFloat(a) / 60;
}
this.ConvertLPStoLPM = function (a) {
  return parseFloat(a) * 60;
}
this.ConvertLPMHtoLPSH = function (a) {
  return parseFloat(a) / 60;
}
this.ConvertLPSHtoLPMH = function (a) {
  return parseFloat(a) * 60;
}
this.ConvertLPMtoM3H = function (a) {
  return parseFloat(a) * 0.06;
}
this.ConvertM3HtoLPM = function (a) {
  return parseFloat(a) / 0.06;
}
this.ConvertINtoMM = function (a) {
  return parseFloat(a) * 25.4;
}