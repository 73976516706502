import axios from "axios";

const authClient = axios.create({
  baseURL: "https://auth.nelsonirrigation.com",
  //baseURL: "https://dev-services.nelsonirrigation.com/identity",
  withCredentials: false,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "",
  },
})

const sprinklerClient = axios.create({
  baseURL: "https://services.nelsonirrigation.com/scservices",
  withCredentials: false,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "",
  },
});

export default {
  data() {
    return {
      token: null,
    };
  },
  async fetchToken() {
    if (this.token != null) return this.token;
    const params =
      "client_id=performance_client&client_secret=performancesecret&grant_type=client_credentials&scope=PerformanceData";
    const _token = await authClient.post("/connect/token", params);
    this.token = _token.data.access_token;
    return this.token;
  },
  async fetchFromUrl(url) {
    const token = await this.fetchToken();
    const headers = { Authorization: "Bearer " + token, };
    const response = await sprinklerClient.get(
      url,
      { headers }
    );
    return response.data;
  },
  async fetchSprinklers() {
    return await this.fetchFromUrl("api/Products/models");
  },
  async fetchPlateParameters(sprModel, platePn, nozType) {
    return await this.fetchFromUrl("api/Performance/Spacing/" + sprModel + "/" + platePn + "/" + nozType);
  },
  async fetchPerformance(performanceType, sprModel, platePn, nozType) {
    return await this.fetchFromUrl("api/Performance/Table/" + performanceType + "/" + sprModel + "/" + platePn + "/" + nozType);
  },
  async fetchNozzleFlows(nozPn) {
    return await this.fetchFromUrl("api/Performance/NozzleFlows/3NV/" + nozPn);
  },
  async fetchNozzleForFlow(qReq, fPres) {
    const token = await this.fetchToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    const partNumbers = ["12035-006","12035-007","12035-008","12035-009","12035-010","12035-011","12035-012","12035-013","12035-014","12035-015",
    "12035-016","12035-017","12035-018","12035-019","12035-020","12035-021","12035-022","12035-023","12035-024","12035-025","12035-026","12035-027",
    "12035-028","12035-029","12035-030","12035-031","12035-032","12035-033","12035-034","12035-035","12035-036","12035-037","12035-038","12035-039",
    "12035-040","12035-041","12035-042","12035-043","12035-044","12035-045","12035-046","12035-047","12035-048","12035-049","12035-050"];
    const response = await sprinklerClient.post(
      "api/Performance/NozzleForFlow/" + qReq + "/" + fPres,
      JSON.stringify(partNumbers),
      { headers },
    );
    return response.data;
  },
  async fetchCompare(compareType, height, pressure, nozSize, Plates) {
    const token = await this.fetchToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    const response = await sprinklerClient.post(
      "api/Performance/Compare/" + compareType + "/" + height + "/" + pressure + "/" + nozSize,
      JSON.stringify(Plates),
      { headers },
    );
    return response.data;
  },
};
