<template>
  <v-app>
    <v-toolbar app class="d-flex flex-row">
      <v-img :src="require('@/assets/logo.png')" class="logo"></v-img>
      <button @click="this.sprinklerStore.nozzle_overlay = !this.sprinklerStore.nozzle_overlay"
        :class="this.sprinklerStore.nozzle_overlay ? 'nav-btn-selected' : 'nav-btn'">
        <div>
          <img :src="require('@/assets/svg/nelson_menu_nozzles.svg')" style="height:40px;width:auto;" />
          <h6>Nozzles</h6>
        </div>
      </button>
      <button @click="this.sprinklerStore.compare_overlay = !this.sprinklerStore.compare_overlay"
        :class="this.sprinklerStore.compare_overlay ? 'nav-btn-selected' : 'nav-btn'" class="nav-btn">
        <div>
          <img :src="require('@/assets/svg/nelson_menu_compare.svg')" style="height:40px;width:auto" />
          <h6>Compare</h6>
        </div>
      </button>
      <v-spacer></v-spacer>
      <v-btn size="x-small" href="https://www.nelsonirrigation.com/legal/privacy" target="_blank" plain>Privacy</v-btn>
      <v-btn size="x-small" href="https://www.nelsonirrigation.com/legal/product-performance-notes" target="_blank"
        plain>Notes</v-btn>
      <v-btn size="x-small" href="https://www.nelsonirrigation.com/legal/warranty" target="_blank" plain>Warranty
      </v-btn>
    </v-toolbar>
    <v-main app>
      <v-container fluid>
        <v-row no-gutters height="10vh" id="dropDownRow">
          <v-col class="v-col-12">
            <v-select id="sprSelect" v-model="selectedSpr" :items="this.sprinklerStore.sprinklers" return-object
              :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              style="margin-right: 10px;display: inline-block;border:1px solid black;max-height:60px;padding:0">
              <template v-slot:selection="{ item }">
                <div class="list-item-selected">
                  <div class="thumb-container">
                    <img :src="getUrl(item)" class="thumb" />
                  </div>
                  <div style="display:inline-block">
                    <span class="type-header">{{ getType(item) }}</span><br />
                    <span class="sub-type">{{ getName(item) }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="list-item" @click="setSprinkler(item)">
                  <div class="thumb-container">
                    <img :src="getUrl(item)" class="thumb" />
                  </div>
                  <div style="display:inline-block">
                    <span class="type-header">{{ getType(item) }}</span><br />
                    <span class="sub-type">{{ getName(item) }}</span>
                  </div>
                </div>
              </template>
            </v-select>
            <v-select id="plateSelect" v-model="selectedPlt" return-object :items="this.sprinklerStore.selectedSprinkler.plates"
              :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              style="display:inline-block;border:1px solid black;max-height:60px;padding:0"
              v-if="this.sprinklerStore.selectedPlate !== null" @update:modelValue="setPlate">
              <template v-slot:selection="{ item }">
                <div class="list-item-selected">
                  <div class="thumb-container">
                    <img :src="getUrl(item)" class="thumb"
                      :style="item.raw.orientation == 1 ? 'transform: rotate(180deg);' : ''" />
                  </div>
                  <div style="display:inline-block">
                    <span class="type-header">{{ getType(item) }}</span><br />
                    <span class="sub-type">{{ getName(item) }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="list-item" @click="setPlate(item)">
                  <div class="thumb-container">
                    <img :src="getUrl(item)" class="thumb"
                      :style="item.raw.orientation == 1 ? 'transform: rotate(180deg);' : ''" />
                  </div>
                  <div style="display:inline-block">
                    <span class="type-header">{{ getType(item) }}</span><br />
                    <span class="sub-type">{{ getName(item) }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters id="parameterRow">
          <v-col class="" id="parameterRow">
            <v-container flex>
              <h3 style="display:block">Parameters</h3>
              <v-divider class="title-divider" />
              <v-row>
                <v-col colspan="6">
                  <h6 class="d-flex justify-start" style="margin-top:5px;">PARAMETERS {{ this.isMetric ? "(m)" : "(ft)"
                    }}</h6>
                </v-col>
                <v-col colspan="6">
                  <v-btn-toggle style="height:25px;" width="49%" label="UNITS" v-model="isMetric" rounded borderless>
                    <v-btn :value="false" text>feet</v-btn>
                    <v-btn :value="true" text>meters</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-divider />
              <v-btn-toggle class="d-flex justify-start" width="49%" label="NOZ" v-model="isGrouped" borderless rounded
                style="height:25px;">
                <v-btn width="25%" :value="false" text>show all</v-btn>
                <v-btn :value="true" text>group</v-btn>
              </v-btn-toggle>
              <v-divider />
              <v-btn-toggle mandatory v-if="this.sprinklerStore.secondPressureRangeParameters.length > 0">
                <v-btn style="display:inline" v-on:click="this.sprinklerStore.showSecondPressureRange = false">
                  PRESSURE RANGE 1</v-btn>
                <v-btn style="display: inline;" v-on:click="this.sprinklerStore.showSecondPressureRange = true">
                  PRESSURE RANGE 2</v-btn>
              </v-btn-toggle>
              <v-table fixed-header height="67vh">
                <thead style="background-color:white">
                  <tr>
                    <th style="text-align:center" scope="col">Nozzle #</th>
                    <th style="text-align:center" scope="col">min</th>
                    <th style="text-align:center" scope="col">max</th>
                    <th style="text-align:center" scope="col">spacing limit</th>
                  </tr>
                  <tr>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nozzle in this.sprinklerStore.plateParameters" :key="nozzle">
                    <td style="text-align:center"><b>{{ nozzle.size }}</b><sub class="dot"
                        :style="dotColor(nozzle.size)"></sub>
                    </td>
                    <td style="background-color:#EEEEEE;text-align: center;">{{ this.displayPsiOrBars(nozzle.pmin) }}
                    </td>
                    <td style="background-color:#EEEEEE;text-align: center;">{{ this.displayPsiOrBars(nozzle.pmax) }}
                    </td>
                    <td style="background-color:#EEEEEE;text-align: center;">{{ this.displayFeetOrMeters(nozzle.spacing)
                      }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-container>
          </v-col>
          <v-col class="" height="80vh">
            <v-container flex>
              <h3 style="display:block">Coverage Diameters</h3>
              <v-divider class="title-divider" />
              <v-row>
                <v-col style="border-bottom:3px solid black">
                  <v-card style="min-height:180px;height:180px;width:80%;display:inline-block;" elevation="0">
                    <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                      <line v-for="(height, index) in this.sprinklerStore.coverageDiameters.heights" x1="0"
                        stroke-width="0.25" :key="height"
                        :y1="getHeight(this.sprinklerStore.coverageDiameters.heights.length, index)" x2="98"
                        :y2="getHeight(this.sprinklerStore.coverageDiameters.heights.length, index)"
                        stroke="darkgray" />
                      <g id="sprayGroup">
                        <path id="sprLeftSpray1" d="M5,100 C10,50 30,29 50,22 C45,26 20,29 10,100" stroke="black"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <path id="sprLeftSpray2" d="M10,100 C20,29 45,26 50,22 C47,26 25,29 15,100" stroke="none"
                          fill="rgba(0,0,200,0.10)" />
                        <path id="sprMidSpray" d="M15,100 C25,29 47,26 50,22 C52,26 70,29 85,100" stroke-width="0.25"
                          stroke-dasharray="1 1" fill="rgba(0,0,200,0.05)" stroke="black" />
                        <path id="sprRightSpray1" d="M85,100 C70,29 52,26 50,22 C55,26 75,29 90,100" stroke="none"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <path id="sprRightSpray2" d="M90,100 C75,29 54,26 50,22 C56,26 80,29 95,100" stroke="black"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <circle id="sprPoint" cx="50%" cy="22%" r="4%" stroke="black" stroke-width="0.5" fill="white" />
                      </g>
                      Sorry, your browser does not support inline SVG.
                    </svg>
                  </v-card>
                  <v-btn-toggle v-model="this.coverageHeight" style="display:inline-table;vertical-align:top;width:19%">
                    <v-btn v-for="(height, index) in this.sprinklerStore.coverageDiameters.heights.slice().reverse()"
                      :key="index" @click="adjustWaterHeight('#sprayGroup', index, false)"
                      :style="btnColor(true, height)">
                      {{ this.displayFeetOrMeters(height) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col colspan="6">
                  <h6 class="d-flex justify-start" style="margin-top:5px;">COVERAGE DIAMETERS {{ this.isMetric ? "(m)" :
        "(ft)"
                    }}</h6>
                </v-col>
                <v-col>
                  <v-btn-toggle style="height:25px;" label="UNITS" v-model="isMetric" rounded borderless>
                    <v-btn :value="false" text>feet</v-btn>
                    <v-btn :value="true" text>meters</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-divider />
              <span class="d-flex justify-end"
                style="margin-top:5px;font-weight:bold;font-size:small;margin-right:20px;">PRESSURE {{ this.isMetric ?
        "(bar)" : "(psi)"
                }}</span>
              <v-table height="40vh" fixed-header>
                <thead>
                  <tr>
                    <th style="text-align:center" scope="col">Noz #</th>
                    <th style="text-align:center"
                      v-for="pressure in this.sprinklerStore.coverageDiameters.pressures.slice().reverse()[this.coverageHeight]"
                      :key="pressure">
                      {{ this.displayPsiOrBars(pressure) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nozzle in this.sprinklerStore.coverageDiameters.nozzles" :key="nozzle">
                    <td style="text-align:center"><b>{{ nozzle.size }}</b><sub class="dot"
                        :style="dotColor(nozzle.size)"></sub>
                    </td>
                    <td style="background-color:#EEEEEE;text-align: center;"
                      v-for="diameter in nozzle.diameters.slice().reverse()[this.coverageHeight]" :key="diameter">
                      {{
        this.displayFeetOrMeters(diameter.distance)
      }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-container>
          </v-col>
          <v-col class="" height="80vh">
            <v-container flex>
              <h3 style="display:block">Stream Heights</h3>
              <v-divider class="title-divider" />
              <v-row style="height:214px;">
                <v-col style="border-bottom:3px solid black">
                  <v-card style="min-height:180px;height:180px;width:98%" elevation="0">
                    <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                      <path id="sprSpray" d="M 50 50 q 20 -15 40 0" stroke="black" stroke-width="0.5"
                        fill="rgba(0,0,200,0.05)" stroke-dasharray="1 1" />
                      <path id="midLine" d="M 0 50 L 100 50" stroke="black" stroke-width="0.25px" />
                      <circle id="sprCirc" cx="50%" cy="50%" r="3%" stroke="black" stroke-width="0.5" fill="white" />
                      <image :href="require('@/assets/svg/nelson_icons_height.svg')" height="25" width="3" x="95%"
                        y="33.5%" />
                      Sorry, your browser does not support inline SVG.
                    </svg>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col colspan="6">
                  <h6 class="d-flex justify-center" style="margin-top:5px;">STREAM HEIGHTS {{ this.isMetric ? "(mm)" :
        "(in)"
                    }}</h6>
                </v-col>
                <v-col colspan="6">
                  <v-btn-toggle label="UNITS" v-model="this.isMetric" borderless rounded style="height:25px;">
                    <v-btn :value="false" text>inches</v-btn>
                    <v-btn :value="true" text>mm</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-divider />
              <span class="d-flex justify-end"
                style="margin-top:5px;font-weight:bold;font-size:small;margin-right:20px;">PRESSURE {{ this.isMetric ?
        "(bar)" : "(psi)"
                }}</span>
              <v-table height="40vh" fixed-header>
                <thead>
                  <tr>
                    <th style="text-align:center" scope="col">Noz #</th>
                    <th style="text-align:center"
                      v-for="pressure in this.sprinklerStore.streamHeights.pressures.slice().reverse()[0]"
                      :key="pressure">
                      {{ this.displayPsiOrBars(pressure) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nozzle in this.sprinklerStore.streamHeights.nozzles" :key="nozzle">
                    <td style="text-align:center"><b>{{ nozzle.size }}</b><sub class="dot"
                        :style="dotColor(nozzle.size)"></sub>
                    </td>
                    <td style="background-color:#EEEEEE;text-align: center;" v-for="diameter in nozzle.diameters[0]"
                      :key="diameter">{{
        this.displayInchesOrMillimeters(diameter.distance)
      }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-container>
          </v-col>
          <v-col class="" height="80vh">
            <v-container flex v-if="this.sprinklerStore.uniformity.heights.length > 0">
              <h3 style="display:block">Uniformity</h3>
              <v-divider class="title-divider" />
              <v-row>
                <v-col style="border-bottom:3px solid black">
                  <v-card style="min-height:180px;height:180px;width:80%;display: inline-block;" elevation="0">
                    <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice"
                      v-if="this.sprinklerStore.uniformity.heights.length > 0">
                      <line v-for="(height, index) in this.sprinklerStore.uniformity.heights" x1="0" stroke-width="0.25"
                        :key="height" :y1="getHeight(this.sprinklerStore.uniformity.heights.length, index)" x2="98"
                        :y2="getHeight(this.sprinklerStore.uniformity.heights.length, index)" stroke="darkgray" />
                      <g id="sprayGroup2">
                        <path id="uniLftSpry1" d="M-5,100 C0,50 20,29 40,22 C35,26 10,29 0,100" stroke="black"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <path id="uniLftSpry2" d="M0,100 C10,29 35,26 40,22 C37,26 15,29 5,100" stroke="none"
                          fill="rgba(0,0,200,0.10)" />
                        <path id="uniMdSpry" d="M5,100 C15,29 37,26 40,22 C42,26 60,29 75,100" stroke-width="0.25"
                          stroke-dasharray="1 1" fill="rgba(0,0,200,0.05)" stroke="black" />
                        <path id="uniRghtSpry1" d="M75,100 C60,29 42,26 40,22 C45,26 65,29 80,100" stroke="none"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <path id="uniRghtSpry2" d="M80,100 C65,29 44,26 40,22 C46,26 70,29 85,100" stroke="black"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />

                        <!-- Second copy to show overlapping -->
                        <path id="uniLftSpry1" d="M15,100 C20,50 40,29 60,22 C55,26 30,29 20,100" stroke="black"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <path id="uniLftSpry2" d="M20,100 C30,29 55,26 60,22 C57,26 35,29 25,100" stroke="none"
                          fill="rgba(0,0,200,0.10)" />
                        <path id="uniMdSpry" d="M25,100 C35,29 57,26 60,22 C62,26 80,29 95,100" stroke-width="0.25"
                          stroke-dasharray="1 1" fill="rgba(0,0,200,0.05)" stroke="black" />
                        <path id="uniRghtSpry1" d="M95,100 C80,29 62,26 60,22 C65,26 85,29 100,100" stroke="none"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />
                        <path id="uniRghtSpry2" d="M100,100 C85,29 64,26 60,22 C66,26 90,29 105,100" stroke="black"
                          stroke-width="0.25" fill="rgba(0,0,200,0.10)" stroke-dasharray="1 1" />

                        <circle id="sprPoint1" ref="sprPoint1" cx="40%" cy="22%" r="4%" stroke="black"
                          stroke-width="0.5" fill="white" />
                        <circle id="sprPoint2" ref="sprPoint2" cx="60%" cy="22%" r="4%" stroke="black"
                          stroke-width="0.5" fill="white" />
                      </g>

                    </svg>
                  </v-card>
                  <v-btn-toggle v-model="this.uniformityHeight" rounded
                    style="vertical-align: top;display: inline-table;width:19%">
                    <v-btn v-for="(height, index) in this.sprinklerStore.uniformity.heights.slice().reverse()"
                      :key="index" @click="adjustWaterHeight('#sprayGroup2', index, true)"
                      :style="btnColor(false, height)">
                      {{ this.displayFeetOrMeters(height) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h6 class="d-flex justify-center" style="margin-top:5px;">UNIFORMITY {{ this.isMetric ? "(m)" : "(ft)"
                    }}</h6>
                </v-col>
                <v-col>
                  <v-btn-toggle borderless label="UNITS" v-model="isMetric" rounded style="height:25px;">
                    <v-btn :value="false" text>feet</v-btn>
                    <v-btn :value="true" text>meters</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-divider />
              <span class="d-flex justify-end"
                style="margin-top:5px;font-weight:bold;font-size:small;margin-right:20px;">PRESSURE {{ this.isMetric ?
        "(bar)" : "(psi)"
                }}</span>
              <v-table height="40vh" fixed-header>
                <thead>
                  <tr>
                    <th style="align-text:center" scope="col">Noz #</th>
                    <th style="align-text:center"
                      v-for="pressure in this.sprinklerStore.uniformity.pressures.slice().reverse()[this.uniformityHeight]"
                      :key="pressure">
                      {{ this.displayPsiOrBars(pressure) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nozzle in this.sprinklerStore.uniformity.nozzles" :key="nozzle">
                    <td style="align-text:center"><b>{{ nozzle.size }}</b><sub class="dot"
                        :style="dotColor(nozzle.size)"></sub>
                    </td>
                    <td style="background-color:#EEEEEE;align-text:center"
                      v-for="diameter in nozzle.diameters.slice().reverse()[this.uniformityHeight]" :key="diameter">
                      {{ diameter.distance }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <NozzleOverlay />
      <CompareOverlay />
    </v-main>
  </v-app>
</template>

<script>
import { useSprinklerStore } from "@/stores/SprinklerStore.js";
import * as UnitConversion from "@/services/UnitConversionService.js";
import NozzleOverlay from "./components/NozzleOverlay.vue";
import CompareOverlay from "./components/CompareOverlay.vue";
import gsap from 'gsap';

export default {
  data: () => ({
    baseImgUrl: "https://sprinklerchart.com/img/",
    selectedSpr: null,
    selectedPlt: null,
    selectedCompareType: null,
  }),
  computed: {
    isMetric: {
      get() {
        return this.sprinklerStore.showMetric;
      },
      set(value) {
        this.sprinklerStore.showMetric = value;
      }
    },
    isGrouped: {
      get() {
        return this.sprinklerStore.showGroups;
      },
      set(value) {
        this.sprinklerStore.showGroups = value;
      }
    },
    unitLabel: {
      get() {
        return this.sprinklerStore.showMetric ? this.selectedCompareType.metricType : this.selectedCompareType.englishType;
      },
    },
    coverageHeight: {
      get() {
        return this.sprinklerStore.selectedCoverageHeight;
      },
      set(value) {
        this.sprinklerStore.selectedCoverageHeight = value;
      },
    },
    uniformityHeight: {
      get() {
        return this.sprinklerStore.selectedUniformityHeight;
      },
      set(value) {
        this.sprinklerStore.selectedUniformityHeight = value;
      },
    },
    compareHeight: {
      get() {
        return this.displayFeetOrMeters(this.sprinklerStore.compareInputs.height, 0);
      },
      set(value) {
        this.sprinklerStore.compareInputs.height = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertFtToMeters(value);
        this.sprinklerStore.fetchCompare();
      }
    },
    comparePressure: {
      get() {
        return this.displayPsiOrBars(this.sprinklerStore.compareInputs.pressure, 0);
      },
      set(value) {
        const scaledDownValue = value * 100000;
        this.sprinklerStore.compareInputs.pressure = this.sprinklerStore.showMetric ? scaledDownValue : UnitConversion.ConvertBARtoPSI(scaledDownValue);
        this.sprinklerStore.fetchCompare();
        this.sprinklerStore.fetchNozzleForFlow();
      }
    },
    compareRate: {
      get() {
        return this.displayGPMAOrLPMH(this.sprinklerStore.compareInputs.rate);
      },
      set(value) {
        this.sprinklerStore.compareInputs.rate = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertLPMHtoGPMA(value);
        this.sprinklerStore.fetchCompare();
        this.sprinklerStore.fetchNozzleForFlow();
      }
    },
    compareDistance: {
      get() {
        return this.displayFeetOrMeters(this.sprinklerStore.compareInputs.distance, 0);
      },
      set(value) {
        this.sprinklerStore.compareInputs.distance = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertFtToMeters(value);
        this.sprinklerStore.fetchCompare();
        this.sprinklerStore.fetchNozzleForFlow();
      }
    },
    compareSpacing: {
      get() {
        return this.displayFeetOrMeters(this.sprinklerStore.compareInputs.spacing);
      },
      set(value) {
        this.sprinklerStore.compareInputs.spacing = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertFtToMeters(value);
        this.sprinklerStore.fetchCompare();
        this.sprinklerStore.fetchNozzleForFlow();
      }
    },
    compareFlow: {
      get() {
        return this.displayGpmOrLpm(this.sprinklerStore.compareInputs.flow);
      },
      set(value) {
        this.sprinklerStore.compareInputs.flow = this.sprinklerStore.showMetric ? value : UnitConversion.ConvertLPMtoGPM(value);
        this.sprinklerStore.fetchCompare();
      }
    },
  },
  setup() {
    const sprinklerStore = useSprinklerStore();
    return {
      sprinklerStore,
    };
  },
  async created() {
    await this.sprinklerStore.setup();
    this.selectedSpr = this.sprinklerStore.selectedSprinkler;
    this.selectedPlt = this.sprinklerStore.selectedPlate;
    this.selectedCompareType = this.sprinklerStore.compareTypes[0];
  },
  watch: {
    coverageHeight(newValue) {
      this.adjustWaterHeight('#sprayGroup', newValue, false);
    },
    uniformityHeight(newValue) {
      this.adjustWaterHeight('#sprayGroup2', newValue, true);
    }
  },
  methods: {
    adjustWaterHeight(element, index, isUniformity) {
      var interval = 15;
      if (isUniformity) {
        interval = 60 / this.sprinklerStore.uniformity.heights.length;
      }
      else {
        interval = 60 / this.sprinklerStore.coverageDiameters.heights.length;
      }
      var yDelta = interval * (index);
      gsap.to(element, 0.5, { y: +yDelta });

    },
    getHeight(count, index) {
      var y = 0;
      var interval = 60 / count;
      y = ((index) * interval) + interval;
      if (index == 0)
        return (82 - interval);
      else
        return (82 - y);
    },
    dotColor(size) {
      var color = this.sprinklerStore.nozzleColor(size);
      return {
        "background-color": color,
      };
    },
    btnColor(isCoverageTable, height) {
      var ht = 0;
      var borderColor = this.sprinklerStore.selectedPlate.name;
      var backgroundColor = "white";
      var fontColor = "black";

      if (isCoverageTable) {
        ht = Math.floor(160 / (this.sprinklerStore.coverageDiameters.heights.length)).toString() + "px";
        if (this.sprinklerStore.selectedCoverageHeight == this.sprinklerStore.coverageDiameters.heights.slice().reverse().findIndex((ht) => height == ht)) {
          backgroundColor = this.sprinklerStore.selectedPlate.name;
          borderColor = "lightgray";
          fontColor = "white";
        }
      }
      else { //uniformity
        if (this.sprinklerStore.uniformity != null && this.sprinklerStore.uniformity.heights.length > 0) {
          ht = Math.floor(160 / (this.sprinklerStore.uniformity.heights.length)).toString() + "px";
        }
        if (this.uniformityHeight == this.sprinklerStore.uniformity.heights.slice().reverse().findLastIndex((ht) => height == ht)) {
          backgroundColor = this.sprinklerStore.selectedPlate.name;
          borderColor = "lightgray";
          fontColor = "white";
        }
      }

      return {
        "background-color": backgroundColor,
        "min-height": "20px",
        "vertical-align": "top",
        "margin-top": "5px",
        "height": ht,
        "max-height": ht,
        "border": "4px solid " + borderColor,
        "color": backgroundColor.toLowerCase() == "white" ? "black" : fontColor,
        "border-radius": "0px 15px 15px 15px",
      };
    },
    getUrl(value) {
      if (!value)
        return;
      var spr = value.raw;
      if (spr.marketing_name !== null)
        return this.baseImgUrl + spr.marketing_name + ".png";
      else
        return this.baseImgUrl + spr.nicpn + ".png";
    },
    getType(value) {
      if (!value)
        return;
      if (value.raw.type != null)
        return value.raw.type;
      else
        return "Plate";
    },
    getName(value) {
      if (!value)
        return;
      if (value.raw.displayName() !== null)
        return value.raw.displayName();
      else
        return value.raw.name;
    },
    setSprinkler(value) {
      if (value) {
        this.selectedSpr = value.raw;
        this.selectedPlt = value.raw.plates[0];
        this.sprinklerStore.setSprinkler(value.raw);
      }
    },
    setPlate(value) {
      if (value) {
        this.selectedPlt = value.raw;
        this.sprinklerStore.setPlate(value.raw);
      }
    },

    //These should all be rewritten to use filters
    displayBarsOrPsi(psi) {
      return this.sprinklerStore.showMetric ? UnitConversion.ConvertPSItoBAR(psi).toFixed(1) : psi.toFixed(0);
    },
    displayPsiOrBars(bar, englishDecimals = 1, metricDecimals = 1) {
      const scaledBar = bar / 100000;
      return this.sprinklerStore.showMetric ? scaledBar.toFixed(metricDecimals) : UnitConversion.ConvertBARtoPSI(scaledBar).toFixed(englishDecimals);
    },
    displayGPMAOrLPMH(value) {
      return this.sprinklerStore.showMetric ? value : UnitConversion.ConvertLPMHtoGPMA(value).toFixed(0);
    },
    displayGpmOrLpm(lpm) {
      return this.sprinklerStore.showMetric ? lpm.toFixed(1) : UnitConversion.ConvertLPMtoGPM(lpm).toFixed(1);
    },
    displayFeetOrMeters(meters, englishDecimals = 1, metricDecimals = 1) {
      return this.sprinklerStore.showMetric ? meters.toFixed(metricDecimals) : UnitConversion.ConvertMetersToFt(meters).toFixed(englishDecimals);
    },
    displayInchesOrMillimeters(meters) {
      return this.sprinklerStore.showMetric ? UnitConversion.ConvertMetersToMM(meters).toFixed(1) : UnitConversion.ConvertMetersToIn(meters).toFixed(1);
    },
    displayGallonsOrLiters(liters) {
      return this.sprinklerStore.showMetric ? liters.toFixed(3) : UnitConversion.ConvertLPMtoGPM(liters).toFixed(3);
    },
    plateImageUrl(platePn) {
      return "https://sprinklerchart.com/img/" + platePn + ".png";
    },
  },
  components: { NozzleOverlay, CompareOverlay }
};
</script>

<style>
@media (max-width: 600px) {

  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .select-container {
    width: 50vw;
    height: 60px;
    display: inline-block
  }
  #sprSelect{
    width:50%;
  }
  #plateSelect{
    width:50%;
  }
}

table tr {
  border: 1px solid black;
}

table th {
  border-top: 3px solid black;
  border-right: 1px solid black;
}

table td {
  border: 1px solid black;
}

.list-item-selected {
  max-height: 50px;
  padding-left: 15px;
  padding-top: 5px;
}

.v-field__input {
  padding: 0;
}

.nav-btn {
  width: 70px;
  height: 70px;
  margin: 0;
  padding: 0;
  margin-left: 30px;
}

.nav-btn-selected {
  background-color: lightgray;
  border-top: 5px solid gray;
  height: 70px;
  margin: 0;
  padding: 0;
  margin-left: 30px;
}

.logo {
  max-width: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}

.title-divider {
  border: 3px solid black;
}

.list-item {
  height: 60px;
  padding: 15px;
}

.list-item:hover {
  background-color: lightgray;
}

select,
v-btn {
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.v-input__details {
  display: none;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

v-table {
  /* top: 500px;
  vertical-align: top; */
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-collapse: separate;
  border-spacing: 10;
  margin-left: 10px;
  margin-right: 10px;
}

.thumb {
  width: auto;
  height: 30px;
  display: inline-block;
}

thead td {
  background-color: #e1e7ee;
}

th {
  background: #f5f7fb;
  font-size: 0.8em;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
}

td {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  line-height: 1.2;
  text-align: left;
  padding: 0.3rem;
}

.select-container {
  width: 180px;
  height: 50px;
  display: inline-block
}

.type-header {
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.sub-type {
  font-size: 18px;
  font-weight: bold;
}

.thumb-container {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  margin-top: 5px;
}

.column {
  float: left;
}
</style>
